<template>
  <div class="gallery-details py-8">
    <galleryAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="gallery"
        name="gallery"
        redirect="/gallery"
        @deleted="onDelete"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="gallery-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="gallery-details__divider"></v-divider>

            <GalleryForm :gallery="gallery" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Gallery
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import GalleryForm from '@/views/Home/Gallery/components/GalleryForm'
import GalleryAppbar from '@/views/Home/Gallery/components/GalleryAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import bus from '@/store/modules/bus'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'GalleryDetails',

  components: {
    ViewingTitle,
    GalleryAppbar,
    GalleryForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      gallery: (state) => state.gallery.galleryDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.gallery ? this.gallery.title : null
    },

    hasDeletePermission() {
      return validatePermissions(
        [PERMISSION.GALLERIES_DELETE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getGalleryDetails: 'gallery/getGalleryDetails',
    }),

    ...mapMutations({
      clearGalleryDetails: 'gallery/clearGalleryDetails',
      removeGallery: 'filter/removeGallery',
    }),

    onDelete() {
      bus.$emit('galleryRemoved', this.gallery)
      this.removeGallery(this.gallery)
    },

    async getGallery() {
      this.loading = true
      await this.getGalleryDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getGallery()
  },

  destroyed() {
    this.clearGalleryDetails()
  },

  watch: {
    $route() {
      this.getGallery()
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
